import { useCallback, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AuthService, UserService, queryClient } from '~api'
import { STEPPER_PATH, routes } from '~config'
import env from '~config/env'
import { useAuth } from '~context/AuthContext'
import { Button } from '~design-system'
import { getFrenchCivility } from '~helpers'

import {
  Address,
  Date,
  Email,
  InputText,
  Option,
  OptionMultiple,
  OptionMultipleMetiersRome,
  OptionMultipleProfessionalSituations,
  OptionSingleMetiersRome,
  OptionUpload,
  Password,
  FTConnect,
  Phone,
  Picklist,
  PicklistDepartments,
  PicklistLanguages,
  PicklistMultipleHobbies,
  PicklistMultipleSkills,
  PicklistSubActivityArea,
  PoleEmploiNumber,
  RSANumber,
  Textarea,
  Url,
  Year,
} from './components'

function useStepper(initialPath: string) {
  const [steps, setSteps] = useState<Stepper[]>([])
  const [answers, setAnswers] = useState<(string | string[])[]>([])
  const [isTyping, setIsTyping] = useState(!!initialPath)

  const hasFinalFetched = useRef<boolean>()
  const navigate = useNavigate()
  const { getValues } = useFormContext()
  const {
    authState: { user },
  } = useAuth()

  const updateUser = async () => {
    hasFinalFetched.current = true
    const data = await UserService.apiGetUser()
    queryClient.setQueryData('user', data)
  }

  // COMMENT THIS FUNCTION
  let fetchStepTiming = 1000
  if (env.APP_ENV && env.APP_ENV === 'development') {
    fetchStepTiming = 100
  }

  const fetchStep = useCallback(
    async (step: string) => {
      setIsTyping(true)
      const { data } = await AuthService.getStep(step)
      setTimeout(() => {
        setIsTyping(false)
        setSteps((accumulatedSteps) => [...accumulatedSteps, data])
      }, fetchStepTiming)
    },
    [fetchStepTiming]
  )

  // COMMENT THIS FUNCTION
  useEffect(() => {
    fetchStep(initialPath)
  }, [fetchStep, initialPath])

  // COMMENT THIS FUNCTION
  const patchStep = useCallback(
    async (path: string, body: any) => {
      try {
        setIsTyping(true)
        const { data, status, message } = await AuthService.patchStep(path, body)

        const paramsWaitingValidation = message === 'waiting_validation' ? '&message=waiting_validation' : ''

        if (status === 'OK' || status === 'KO') {
          const step = path.split('step=')[1]
          navigate({
            pathname: routes.authenticated.eligibilityResult,
            search: `type=${status}&redirect=${data.redirectRegisterPathUrl}&step=${step}${paramsWaitingValidation}`,
          })
          return
        }

        if (data.action) {
          fetchStep(data.action.split(`goto:${STEPPER_PATH}`)[1])
        }
      } catch {
        console.error('received error with patch step for the Chat component')
      }
    },
    [fetchStep, navigate]
  )

  // COMMENT THIS FUNCTION
  const handleAnswer = (action: Answer['action'], payload: any) => {
    if (!action) {
      return
    }
    if (action.startsWith('goto')) {
      // On the register part, we only need to fetch the next step (no api call until the summary screen)
      if (action.includes(STEPPER_PATH)) {
        fetchStep(action.split(`goto:${STEPPER_PATH}`)[1])
        return
      }
      patchStep(action.split('goto:')[1], payload)
    }
    if (action.startsWith('external')) {
      window.location.replace(action.split('external:')[1])
    }
  }

  // COMMENT THIS FUNCTION
  const getBotDialogs = (step: Stepper) => {
    return step
      .filter(({ type }) => type === 'html')
      .map((step: Stepper[0]) => {
        const { label, ...rest } = step
        return {
          ...rest,
          label: label
            .replace('{$firstname}', getValues('firstname'))
            .replace('{$lastname}', getValues('lastname'))
            .replace('{$civility}', getFrenchCivility(getValues('civility'))),
        }
      })
  }

  // COMMENT THIS FUNCTION
  const renderQuestion = (dialog: Stepper, index: number) => {
    const isCurrentStep = index === steps.length - 1
    const dialogTypes = dialog.map(({ type }) => type)

    if (!isCurrentStep) {
      return null
    }

    return dialogTypes.map((dialogType, index) => {
      switch (dialogType) {
        case 'option':
          return (
            <Option
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'professional_experiences':
        case 'option_upload':
          return (
            <OptionUpload
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              user={user}
              setAnswers={(answer: string | string[]) =>
                setAnswers([...answers, answer])
              }
              key={`index-${index}-type-${dialogType}`}
              handleAnswer={handleAnswer}
              fetchStep={(step: string) => fetchStep(step)} // TODO Check if working
            />
          )

        case 'textarea':
          return (
            <Textarea
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'input':
          return (
            <InputText
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'email':
          return (
            <Email
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'password':
          return (
            <Password
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'phone':
          return (
            <Phone
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'pole_emploi_number':
          return (
            <PoleEmploiNumber
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'rsa_number':
          return (
            <RSANumber
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'date':
          return (
            <Date
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )
        case 'pe_connect':
          return (
            <FTConnect
              dialog={dialog}
              index={index}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'picklist':
          return (
            <Picklist
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'picklist_departements':
          return (
            <PicklistDepartments
              dialog={dialog}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'picklist_languages':
          return (
            <PicklistLanguages
              dialog={dialog}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string[]) =>
                setAnswers([...answers, answer])
              }
              handleAnswer={handleAnswer}
            />
          )

        case 'picklist_multiple_hobbies':
          return (
            <PicklistMultipleHobbies
              dialog={dialog}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'picklist_multiple_skills':
          return (
            <PicklistMultipleSkills
              dialog={dialog}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'option_multiple':
          return (
            <OptionMultiple
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'address_perso':
        case 'address_sponsor':
        case 'address_parents':
          return (
            <Address
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'year':
          return (
            <Year
              dialog={dialog}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'url':
          return (
            <Url
              dialog={dialog}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'picklist_sub_activity_area':
          return (
            <PicklistSubActivityArea
              dialog={dialog}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'option_multiple_metiers_rome':
          return (
            <OptionMultipleMetiersRome
              dialog={dialog}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'option_single_metiers_rome':
          return (
            <OptionSingleMetiersRome
              dialog={dialog}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string) => setAnswers([...answers, answer])}
              handleAnswer={handleAnswer}
            />
          )

        case 'option_multiple_professional_situations':
          return (
            <OptionMultipleProfessionalSituations
              dialog={dialog}
              dialogType={dialogType}
              index={index}
              key={`index-${index}-type-${dialogType}`}
              setAnswers={(answer: string[]) =>
                setAnswers([...answers, answer])
              }
              handleAnswer={handleAnswer}
            />
          )

        case 'final': {
          !hasFinalFetched.current && updateUser()
          return (
            <Button
              isLoading={user?.onboardingNeeded !== 'false'}
              disabled={user?.onboardingNeeded !== 'false'}
              key={`index-${index}-type-${dialogType}`}
              onClick={() =>
                navigate({
                  pathname: routes.authenticated.home,
                })
              }
            >
              Aller à l'accueil
            </Button>
          )
        }

        default:
          return null
      }
    })
  }

  return {
    answers,
    getBotDialogs,
    isTyping,
    renderQuestion,
    steps,
  }
}

export default useStepper
