import { useFormContext } from 'react-hook-form'

import { Box, InputText } from '~design-system'

const EditInfos = () => {
  // Define Form
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  return (
    <Box w="100%">
      <InputText
        id="rsaNumber"
        placeholder="Saisi ici ton numéro RSA"
        label="Numéro RSA"
        type="text"
        autoFocus
        maxLength={7}
        errorMessage={errors?.rsaNumber?.message}
        defaultValue={watch('rsaNumber')}
        {...register('rsaNumber', {
          required: false,
          validate: (v) => v?.length === 7 || !v?.length || !v,
        })}
      />

      <InputText
        id="poleEmploiNumber"
        placeholder="Saisi ici ton numéro France Travail"
        label="Numéro France Travail"
        type="text"
        maxLength={12}
        errorMessage={errors?.poleEmploiNumber?.message}
        defaultValue={watch('poleEmploiNumber')}
        {...register('poleEmploiNumber', {
          required: false,
          validate: (v) => v?.length <= 12 || !v,
        })}
      />

      <InputText
        id="phone"
        placeholder="Saisi ici ton numéro de téléphone"
        label="Numéro de téléphone"
        type="text"
        maxLength={30}
        errorMessage={errors?.phone?.message}
        defaultValue={watch('phone')}
        {...register('phone', {
          required: false,
          validate: (v) =>
            (v?.length <= 30 && v?.length >= 10) || !v?.length || !v,
        })}
      />
    </Box>
  )
}

export default EditInfos
