import { EditIcon } from '~components'
import { Box, CardLink, Divider, Grid, SmallText, Text } from '~design-system'
import { isYoung } from '~helpers'

import {
  BooleanCard,
  DegreeCard,
  ExperienceCard,
  InformationCard,
  LanguageCard,
  TagList,
} from '../components'
import { AddContainer, Container } from '../styles'

interface Props {
  handleEditInfos: (infos: EditInfos) => void
  isEditable?: boolean
  user: User
}

const RightPanel = ({ user, isEditable = false, handleEditInfos }: Props) => {
  const sectors = [user.sector?.domain, user.sector?.subDomain].filter(
    (e) => !!e
  )
  const curiculumVitae = user.resumes && user.resumes[0]

  return (
    <Container f={3}>
      {/* PROFESSIONNAL PROJECT (Young) */}
      {isYoung(user) && (
        <Box>
          <Box di="f" ai="fs" jc="sb">
            <Text weight="medium" mb={2}>
              Projet professionnel
            </Text>
            <EditIcon
              isVisible={isEditable}
              label="Modifier votre Projet professionnel"
              onClick={() =>
                handleEditInfos({
                  slug: 'professionalProject',
                  title: 'Modifier votre Projet professionnel',
                })
              }
            />
          </Box>
          {user.professionalProject ? (
            <Text textColor="greyDarkest" weight="regular" mt={1}>
              {user.professionalProject}
            </Text>
          ) : (
            <SmallText textColor="greyDarker" mt={4}>
              Aucun projet professionnel renseigné
            </SmallText>
          )}
          <Divider mt={5} mb={5} />
        </Box>
      )}

      {/* DEGREES (Young) */}
      {isYoung(user) && (
        <Box>
          <Box di="f" ai="fs" jc="sb">
            <Text weight="medium" mb={4}>
              Diplômes
            </Text>
            {isEditable && user.degrees && user.degrees.length < 5 && (
              <AddContainer>
                <SmallText
                  textColor="primary"
                  as="button"
                  aria-label="Ajouter un diplôme"
                  onClick={() =>
                    handleEditInfos({
                      slug: 'degrees',
                      title: 'Ajouter un diplôme',
                    })
                  }
                >
                  + Ajouter
                </SmallText>
              </AddContainer>
            )}
          </Box>
          {!!user.degrees?.length ? (
            <Grid
              rowGap={5}
              columnGap={5}
              templateColums="repeat(auto-fill, minmax(435px, 1fr))"
            >
              {user.degrees?.map((degree: Degree) => (
                <DegreeCard
                  key={degree.id}
                  degree={degree}
                  isEditable={isEditable}
                  setEditInfos={handleEditInfos}
                />
              ))}
            </Grid>
          ) : (
            <SmallText textColor="greyDarker">
              Aucun diplôme renseigné
            </SmallText>
          )}
          <Divider mt={8} mb={5} />
        </Box>
      )}

      {/* DESCRIPTION (Sponsor) */}
      {!isYoung(user) && (
        <Box>
          <Box di="f" ai="fs" jc="sb">
            <Text weight="medium" mb={2}>
              Description
            </Text>
            <EditIcon
              isVisible={isEditable}
              label="Modifier votre Description"
              onClick={() =>
                handleEditInfos({
                  slug: 'description',
                  title: 'Modifier votre Description',
                })
              }
            />
          </Box>
          {user.description ? (
            <Text textColor="greyDarkest" weight="regular" mt={1}>
              {user.description}
            </Text>
          ) : (
            <SmallText textColor="greyDarker" mt={4}>
              Aucune description renseignée
            </SmallText>
          )}
          <Divider mt={5} mb={5} />
        </Box>
      )}

      {/* SECTORS (Sponsor) */}
      {!isYoung(user) && (
        <Box>
          <Box di="f" ai="fs" jc="sb" f={1}>
            <TagList label="Secteur et sous-secteur" tags={sectors} />
            <EditIcon
              isVisible={isEditable}
              mt={1}
              label="Modifier vos secteur et sous-secteur"
              onClick={() =>
                handleEditInfos({
                  slug: 'sector',
                  title: 'Modifier vos secteur et sous-secteur',
                })
              }
            />
          </Box>
          <Divider mt={5} mb={5} />
        </Box>
      )}

      {/* HOBBIES & SKILLS (Sponsor) */}
      {!isYoung(user) && (
        <Box>
          <Grid
            rowGap={0}
            columnGap={5}
            templateColums="repeat(auto-fit, minmax(275px, 1fr))"
          >
            <Box di="f" fd="c">
              <Box di="f" ai="fs" jc="sb" f={1}>
                <TagList label="Centres d’intérêts" tags={user.hobbies} />
                <EditIcon
                  isVisible={isEditable}
                  mt={1}
                  label="Modifier vos centres d’intérêts"
                  onClick={() =>
                    handleEditInfos({
                      slug: 'hobbies',
                      title: 'Modifier vos centres d’intérêts',
                    })
                  }
                />
              </Box>
              <Divider mt={5} mb={5} />
            </Box>

            <Box di="f" fd="c">
              <Box di="f" ai="fs" jc="sb" f={1}>
                <TagList label="Thèmes d'accompagnement" tags={user.skills} />
                <EditIcon
                  isVisible={isEditable}
                  mt={1}
                  label="Modifier vos thèmes d'accompagnement"
                  onClick={() =>
                    handleEditInfos({
                      slug: 'skills',
                      title: "Modifier vos thèmes d'accompagnement",
                    })
                  }
                />
              </Box>
              <Divider mt={5} mb={5} />
            </Box>
          </Grid>
        </Box>
      )}

      {/* EXPERIENCES (Young AND Sponsor) */}
      <Box mb={5}>
        <Box di="f" ai="fs" jc="sb">
          <Text weight="medium" mb={4}>
            Expériences
          </Text>
          {isEditable && user.experiences?.length < 5 && (
            <AddContainer>
              <SmallText
                textColor="primary"
                as="button"
                aria-label="Ajouter une expérience"
                onClick={() =>
                  handleEditInfos({
                    slug: 'experiences',
                    title: 'Ajouter une Expérience',
                  })
                }
              >
                + Ajouter
              </SmallText>
            </AddContainer>
          )}
        </Box>
        {!!user.experiences?.length ? (
          <Grid
            rowGap={5}
            columnGap={5}
            templateColums="repeat(auto-fill, minmax(435px, 1fr))"
          >
            {user.experiences.map((experience: Experience) => (
              <ExperienceCard
                key={experience.name}
                experience={experience}
                isEditable={isEditable}
                setEditInfos={handleEditInfos}
              />
            ))}
          </Grid>
        ) : (
          <SmallText textColor="greyDarker">
            Aucune expérience renseignée
          </SmallText>
        )}
        {isYoung(user) && (
          <Grid
            rowGap={5}
            columnGap={5}
            templateColums="repeat(auto-fit, minmax(275px, 1fr))"
            mt={5}
          >
            <BooleanCard
              label="Petits boulots en cours"
              value={user?.isDoingOddJobs}
              name="odd_jobs"
              isEditable={isEditable}
            />
            <BooleanCard
              label="Bénévolat"
              value={user?.isVolonteering}
              name="volonteering"
              isEditable={isEditable}
            />
          </Grid>
        )}
        <Divider mb={5} mt={8} />
      </Box>

      {/* LANGUAGES (Young) */}
      {isYoung(user) && (
        <Box>
          <Box di="f" ai="fs" jc="sb">
            <Text weight="medium" mb={4}>
              Langues
            </Text>
            {isEditable && (
              <AddContainer>
                <SmallText
                  textColor="primary"
                  as="button"
                  aria-label="Ajouter une langue"
                  onClick={() =>
                    handleEditInfos({
                      slug: 'languages',
                      title: 'Ajouter une Langue',
                    })
                  }
                >
                  + Ajouter
                </SmallText>
              </AddContainer>
            )}
          </Box>
          {!!user.languages?.length ? (
            <Grid
              rowGap={5}
              columnGap={5}
              templateColums="repeat(auto-fill, minmax(435px, 1fr))"
            >
              {user.languages.map((language) => (
                <LanguageCard
                  key={language.id}
                  language={language}
                  isEditable={isEditable}
                  setEditInfos={handleEditInfos}
                />
              ))}
            </Grid>
          ) : (
            <SmallText textColor="greyDarker">
              Aucune langue renseignée
            </SmallText>
          )}
          <Divider mt={8} mb={5} />
        </Box>
      )}

      {/* LINKS (Young AND Sponsor) */}
      <Box>
        <Box di="f" ai="fs" jc="sb">
          <Text weight="medium" mb={4}>
            Liens
          </Text>
          <EditIcon
            isVisible={isEditable}
            label="Modifier vos liens"
            onClick={() =>
              handleEditInfos({
                slug: 'links',
                title: 'Modifier vos liens',
              })
            }
          />
        </Box>
        {isYoung(user) && (
          <CardLink
            icon="avatar"
            label="CV"
            link={curiculumVitae?.url}
            linkLabel={!curiculumVitae?.name ? 'Lien à définir' : undefined}
            mb={2}
          />
        )}
        <CardLink
          icon="linkedin-square"
          label="Mon profil LinkedIn"
          link={user.linkedinUrl}
          linkLabel={!user.linkedinUrl ? 'Lien à définir' : undefined}
        />
      </Box>

      {/* INFOS (Young) */}
      {isYoung(user) && (
        <>
          <Divider mt={8} mb={5} />
          <Box mb={5}>
            <Box di="f" ai="fs" jc="sb">
              <Text weight="medium" mb={4}>
                Informations Générales
              </Text>
              <EditIcon
                isVisible={isEditable}
                label="Modifier vos informations générales"
                onClick={() =>
                  handleEditInfos({
                    slug: 'infos',
                    title: 'Modifier vos informations générales',
                  })
                }
              />
            </Box>
            <Grid
              rowGap={5}
              columnGap={5}
              templateColums="repeat(auto-fill, minmax(435px, 1fr))"
            >
              {isEditable && ( // These Personnal Data are only visible by the young
                <>
                  <InformationCard
                    label="Numéro RSA"
                    value={user.rsaNumber || '-'}
                  />
                  <InformationCard
                    label="Numéro France Travail"
                    value={user.poleEmploiNumber || '-'}
                  />
                </>
              )}
              <InformationCard
                label="Numéro de téléphone"
                value={user.phone || '-'}
              />
              <BooleanCard
                label="Permis B"
                value={user?.hasDrivingLicenceB}
                name="hasDrivingLicenceB"
                isEditable={isEditable}
                smallLabel
              />
            </Grid>
          </Box>
        </>
      )}
    </Container>
  )
}

export default RightPanel
